import useTranslation from 'next-translate/useTranslation';
import { StyledDiv, StyledHeading, StyledSection } from './HardCodedHorizontalBannerComponent.styles';

const HorizontalBannerComponent = () => {
  const { t } = useTranslation('startPage');

  return (
    <StyledSection fixed={false} clickable={false}>
      <StyledDiv>
        <StyledHeading color="white" fixed={false} variant="h2">
          {t('startPage:businessIdea->firstLine')} <br />
          {t('startPage:businessIdea->secondLine')}&nbsp;{t('startPage:businessIdea->secondLineEnd')}
        </StyledHeading>
      </StyledDiv>
    </StyledSection>
  );
};

export default HorizontalBannerComponent;
