import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import variables from '@styles/variables';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';

export const StyledSection = styled.section<{ fixed: boolean; clickable: boolean }>`
  background-color: ${variables.colors.primary};
  min-height: ${rem(180)};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: ${rem(64)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(82)};
    min-height: ${rem(200)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-top: ${rem(96)};
    min-height: ${rem(310)};
  }

  ${(props) =>
    props.fixed &&
    css`
      height: ${rem(105)};
      min-height: ${rem(105)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        height: ${rem(312)};
        min-height: ${rem(312)};
      }
    `}

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

export const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${rem(35)} ${rem(20)};
  text-align: center;
  align-items: center;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(45)} ${rem(40)};
  }
`;

export const StyledHeading = styled(Heading)<{ fixed: boolean }>`
  font-size: ${rem(32)};
  line-height: ${rem(36)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    font-size: ${rem(40)};
    line-height: ${rem(44)};
    margin-bottom: ${rem(20)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    font-size: ${rem(60)};
    line-height: ${rem(64)};
  }

  ${(props) =>
    props.fixed &&
    css`
        margin-bottom: 0;
        ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
          margin-bottom: ${rem(20)};
      `}
`;

export const StyledText = styled(Text)`
  margin-bottom: ${rem(20)};
`;

export const StyledButton = styled(Button)`
  &:hover:not(.disabled) {
    @media (hover: hover) {
      opacity: 0.8;
    }
  }
`;
